
import { Component, Vue } from 'vue-property-decorator';
import { sleep } from '@/utils/helpers';
import assetsModule from '@/store/modules/assetsModule';
import scheduleModule from '@/store/modules/scheduleModule';
import Multiselect from 'vue-multiselect';
import { v4 as uuidv4 } from 'uuid';

@Component({
  components: {
    Multiselect,
  },
})
export default class AddShift extends Vue {
  selectedColumnType = 'DETAILS';

  userSelectionVal = '';

  yourValue = '';

  refreshResponseDataKey = 0;

  addShiftChecklistPopup = false;

  addShiftIsLoading = false;

  addShiftGroupSelected = '';

  dataLoading = false;

  popupBackground = false;

  selectedDayOfWeek: any[] =[];

  daysOfWeek = [['Tue', 'Tuesday'], ['Wed', 'Wednesday'], ['Thu', 'Thursday'], ['Fri', 'Friday'], ['Sat', 'Saturday']];

  selectedTemplate = -1

  templateOptions: any[] = [];

  timeOptions: any[] = [];

  dayOptions: any[] = [];

  shiftEndTimeOptions: any[] = ['00:00'];

  recurEndTimeOptions: any[] = ['00:00'];

  userOptions: any[] = [];

  assetOptions: any[] = [];

  assetType = '';

  shiftDate = '';

  errorMessages = {
 shiftDate: '', recurStartDate: '', recurEndDate: '', weekdays: '', assetType: '', assetName: '', username: '',
}

  dataErrorMessage = '';

  userName = '';

  assetName = '';

  shiftTemplates: any;

  allAssetTypes: any;

  shiftStartTime='';

  shiftEndTime='';

  recurEndDate='';

  recurType='';

  onDays=1;

  offDays=1;

  allDay = false;

  disableShiftStartTime = false;

  disableShiftEndTime = false;

  isSavingShift = false

  get activeComponent() {
    return scheduleModule.activeComponent;
  }

  get activeColumns() {
    return scheduleModule.activeComponentResponseColumns;
  }

  get scheduledRowComponents(): any[] {
      return scheduleModule.scheduledRowComponents;
  }

  changeAllDay() {
    this.allDay = !(this.allDay);
    if (this.allDay === false) {
      this.disableShiftStartTime = false;
      this.disableShiftEndTime = false;
    } else {
      this.disableShiftStartTime = true;
      this.disableShiftEndTime = true;
    }
  }

  setTimeOptions() {
    function pad(n, width, z) {
      const znew = z || '0';
      const nnew = `${n}`;
      return nnew.length >= width ? nnew : new Array(width - nnew.length + 1).join(znew) + nnew;
    }
    for (let i = 0; i <= 23; i++) {
      for (let j = 0; j < 60; j += 15) {
      if (i === 0) {
        if (j === 0) {
          this.timeOptions.push('12:00am');
        } else {
          this.timeOptions.push(`12:${pad(j, 2, '0')}am`);
        }
      } else if (i < 12) {
        if (j === 0) {
          this.timeOptions.push(`${i}:00am`);
        } else {
          this.timeOptions.push(`${i}:${pad(j, 2, '0')}am`);
        }
      } else if (i === 12) {
        if (j === 0) {
          this.timeOptions.push(`${i}:00pm`);
        } else {
          this.timeOptions.push(`${i}:${pad(j, 2, '0')}pm`);
        }
      } else if (j === 0) {
          this.timeOptions.push(`${i - 12}:00pm`);
        } else {
          this.timeOptions.push(`${i - 12}:${pad(j, 2, '0')}pm`);
        }
      }
    }
    this.shiftStartTime = '12:00am';
    this.setShiftEndTimeOptions(this.shiftStartTime);
    this.shiftEndTime = '11:45pm';
  }

  setDayOptions() {
    for (let i = 1; i <= 30; i++) {
      this.dayOptions.push(i);
    }
  }

  setShiftEndTimeOptions(value) {
    if (typeof value === 'string' && value.constructor === String) {
      this.shiftEndTimeOptions = this.timeOptions.slice();
    }
  }

  setRecurEndTimeOptions(value) {
    if (typeof value === 'string' && value.constructor === String) {
      this.recurEndTimeOptions = this.timeOptions.slice();
    }
  }

  clickDayOfWeek(value) {
    if (this.selectedDayOfWeek.indexOf(value) === -1) {
      this.selectedDayOfWeek.push(value);
    } else {
      this.selectedDayOfWeek.splice(this.selectedDayOfWeek.indexOf(value), 1);
    }
  }

  closeAddShiftPopup() {
    this.$emit('close-add-shift-popup');
  }

  dateRangeOverlaps(aStart, aEnd, bStart, bEnd) {
    if (aStart <= bStart && bStart <= aEnd) return true;
    if (aStart <= bEnd && bEnd <= aEnd) return true;
    if (bStart < aStart && aEnd < bEnd) return true;
    return false;
  }

  timeToMilitaryTimeString(time) {
    function pad(n, width, z) {
        const znew = z || '0';
        const nnew = `${n}`;
        return nnew.length >= width ? nnew : new Array(width - nnew.length + 1).join(znew) + nnew;
    }

    const hour = time.split(':')[0];
    let minutes = time.split(':')[1];
    minutes = minutes.replace('am', '');
    minutes = minutes.replace('pm', '');
    const meridian = time.includes('am') ? 'AM' : 'PM';
    if (meridian === 'AM' && hour === 12) {
      return `${pad(0, 2, '0')}:${pad(minutes, 2, '0')}`;
    } if (meridian === 'AM') {
      return `${pad(hour, 2, '0')}:${pad(minutes, 2, '0')}`;
    } if (meridian === 'PM' && hour === 12) {
      return `${pad(12, 2, '0')}:${pad(minutes, 2, '0')}`;
    }
      return `${pad(parseInt(hour) + 12, 2, '0')}:${pad(minutes, 2, '0')}`;
  }

  async addShiftSave() {
    // @ts-ignore
  this.isSavingShift = true;
  let offset;
  await sleep(400);
    let errorFlag = false;
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const k in this.errorMessages) {
      this.errorMessages[k] = '';
    }
    if (this.shiftDate === '') {
      errorFlag = true;
      this.errorMessages.shiftDate = 'Please select a date';
    }
    if (this.recurType !== '') {
    const sixMonthsLater = new Date();
    const newDate = new Date(sixMonthsLater.setMonth(sixMonthsLater.getMonth() + 6));
    if ((new Date(this.recurEndDate)).getTime() > newDate.getTime() && this.recurType !== '') {
      errorFlag = true;
      this.errorMessages.weekdays = 'Please select a range less than 6 months';
      this.isSavingShift = false;
      return;
    }

      if (this.shiftDate === '') {
        errorFlag = true;
        this.errorMessages.shiftDate = 'Please select start date';
      }
      if (this.recurEndDate === '') {
        errorFlag = true;
        this.errorMessages.recurEndDate = 'Please select end date';
      }
      if (this.selectedDayOfWeek.length === 0 && this.recurType !== 'ON_OFF') {
        errorFlag = true;
        this.errorMessages.weekdays = 'Please select weekdays';
      }
    }
    if (this.assetType === '') {
      errorFlag = true;
      this.errorMessages.assetType = 'Please select asset type';
    }

    if (this.assetName === '') {
      errorFlag = true;
      this.errorMessages.assetName = 'Please select asset name';
    }

    if (errorFlag) {
    this.isSavingShift = false;
      return;
  }

    let startTime; let endTime; let
shiftTemplateID;
    if (this.selectedTemplate >= 0) {
      startTime = this.shiftTemplates[this.selectedTemplate].StartTime;
      endTime = this.shiftTemplates[this.selectedTemplate].EndTime;
      scheduleModule.addPersonnelScheduleShiftTemplates({
        id: this.shiftTemplates[this.selectedTemplate].ID,
        name: this.shiftTemplates[this.selectedTemplate].TemplateName,
        color: this.shiftTemplates[this.selectedTemplate].Color,
      });
    shiftTemplateID = this.shiftTemplates[this.selectedTemplate].ID;
    } else if (this.allDay) {
      offset = (new Date().getTimezoneOffset()) / 60;
        startTime = '00:00';
        endTime = '23:59';
      } else {
        startTime = this.timeToMilitaryTimeString(this.shiftStartTime);
        endTime = this.timeToMilitaryTimeString(this.shiftEndTime);
      }

    function pad(n, width, z) {
        const znew = z || '0';
        const nnew = `${n}`;
        return nnew.length >= width ? nnew : new Array(width - nnew.length + 1).join(znew) + nnew;
    }

  // If this is a newly selected well, then subtract times by the timezone,
  // and if the start date is less than zero, subract a day from the datetime
  // Also, if the recurType exists, then loop through and add several shifts that recur
  // Figure out how to compbine total existing and new/edited conponents, and
  // how to display them, maybe setup new inner rows
  offset = (new Date().getTimezoneOffset()) / 60;
  startTime = new Date(`${this.shiftDate}T${startTime}:00`);
  endTime = new Date(`${this.shiftDate}T${endTime}:00`);
  startTime.setTime(startTime.getTime() + (offset * 60 * 60 * 1000));
  endTime.setTime(endTime.getTime() + (offset * 60 * 60 * 1000));

  const startDatetimeString = `${startTime.getFullYear()}-${pad(startTime.getMonth() + 1, 2, '0')}-${
    pad(startTime.getDate(), 2, '0')}T${pad(startTime.getHours(), 2, '0')}:${
      pad(startTime.getMinutes(), 2, '0')}:${pad(startTime.getSeconds(), 2, '0')}`;
  const endDatetimeString = `${endTime.getFullYear()}-${pad(endTime.getMonth() + 1, 2, '0')}-${
    pad(endTime.getDate(), 2, '0')}T${pad(endTime.getHours(), 2, '0')}:${
      pad(endTime.getMinutes(), 2, '0')}:${pad(endTime.getSeconds(), 2, '0')}`;
  const shiftResults = {
    type: 'new',
    date: this.shiftDate,
    username: this.userName === '' ? null : this.userName,
    startTime: startDatetimeString,
    endTime: endDatetimeString,
    assetType: this.assetType,
    asset: this.assetName,
    recurType: this.recurType,
    recurStartDate: this.shiftDate.slice(0, 19), // "2022-04-01T01:00:00"
    recurEndDate: this.recurEndDate.slice(0, 19), // "2022-04-05T01:00:00"
    weekdays: JSON.stringify(this.selectedDayOfWeek),
    onDays: this.onDays,
    offDays: this.offDays,
    workspace_id: scheduleModule.activePersonnelWorkspace.ID,
    schedule_id: scheduleModule.activePersonnelSchedule.ID,
    selection_id: this.assetName,
    RowID: this.assetName,
    shiftTemplateID,
  };

  const startDate = shiftResults.startTime;
  const endDate = shiftResults.endTime;

  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  if (this.recurType === 'ON_OFF') {
    const recurStartDateObj = new Date(shiftResults.recurStartDate);
    const recurEndDateObj = new Date(shiftResults.recurEndDate);

    recurStartDateObj.setTime(recurStartDateObj.getTime() + (offset * 60 * 60 * 1000));
    recurEndDateObj.setTime(recurEndDateObj.getTime() + (offset * 60 * 60 * 1000));

    let count = 0;
    const totalDays = parseInt(shiftResults.onDays.toString()) + parseInt(shiftResults.offDays.toString());
    for (let d = new Date(recurStartDateObj.getTime()); d <= recurEndDateObj; d.setDate(d.getDate() + 1)) {
      if (count < shiftResults.onDays) {
        const checkRecurEndDateObj = new Date(d.getTime());
        checkRecurEndDateObj.setDate(checkRecurEndDateObj.getDate() + 1);

        const localComponent = {
          Asset: this.assetName,
          AssetType: this.assetType,
          Color: '#ffffff',
          EndTime: `${checkRecurEndDateObj.getFullYear()}-${pad(checkRecurEndDateObj.getMonth() + 1, 2, '0')}-${
            pad(checkRecurEndDateObj.getDate(), 2, '0')}T${pad((new Date(shiftResults.endTime)).getHours(), 2, '0')}:${
              pad((new Date(shiftResults.endTime)).getMinutes(), 2, '0')}:00`,
          ID: uuidv4(),
          Level: this.assetType,
          RowID: this.assetName,
          ScheduleID: scheduleModule.activePersonnelSchedule.ID,
          StartTime: `${d.getFullYear()}-${pad(d.getMonth() + 1, 2, '0')}-${
            pad(d.getDate(), 2, '0')}T${pad((new Date(shiftResults.startTime)).getHours(), 2, '0')}:${
              pad((new Date(shiftResults.startTime)).getMinutes(), 2, '0')}:00`,
          TemplateID: shiftTemplateID,
          Username: this.userName === '' ? null : this.userName,
          WorkspaceID: scheduleModule.activePersonnelWorkspace.ID,
          type: 'new',
          changed: true,
        };

        scheduleModule.addFilterScheduledComponent({ data: localComponent });
      }
      if ((count + 1) >= totalDays) {
        count = 0;
      } else {
        count += 1;
      }
    }
  } else if (this.recurType === 'WEEKDAYS') {
    const recurStartDateObj = new Date(shiftResults.recurStartDate);
    const recurEndDateObj = new Date(shiftResults.recurEndDate);
    for (let d = new Date(recurStartDateObj.getTime()); d <= recurEndDateObj; d.setDate(d.getDate() + 1)) {
      const day = days[d.getDay()];
      if (this.selectedDayOfWeek.indexOf(day) > -1) {
        const checkRecurEndDateObj = new Date(d.getTime());
        checkRecurEndDateObj.setDate(checkRecurEndDateObj.getDate() + 1);

        const localComponent = {
          Asset: this.assetName,
          AssetType: this.assetType,
          Color: '#ffffff',
          EndTime: `${checkRecurEndDateObj.getFullYear()}-${pad(checkRecurEndDateObj.getMonth() + 1, 2, '0')}-${
            pad(checkRecurEndDateObj.getDate(), 2, '0')}T${pad((new Date(shiftResults.endTime)).getHours(), 2, '0')}:${
              pad((new Date(shiftResults.endTime)).getMinutes(), 2, '0')}:00`,
          ID: uuidv4(),
          Level: this.assetType,
          RowID: this.assetName,
          ScheduleID: scheduleModule.activePersonnelSchedule.ID,
          StartTime: `${d.getFullYear()}-${pad(d.getMonth() + 1, 2, '0')}-${pad(d.getDate(), 2, '0')}T${
            pad((new Date(shiftResults.startTime)).getHours(), 2, '0')}:${
            pad((new Date(shiftResults.startTime)).getMinutes(), 2, '0')}:00`,
          TemplateID: shiftTemplateID,
          Username: this.userName === '' ? null : this.userName,
          WorkspaceID: scheduleModule.activePersonnelWorkspace.ID,
          type: 'new',
          changed: true,
        };

        scheduleModule.addFilterScheduledComponent({ data: localComponent });
      }
    }
  } else {
    const localComponent = {
      Asset: this.assetName,
      AssetType: this.assetType,
      Color: '#ffffff',
      EndTime: endDate,
      ID: uuidv4(),
      Level: this.assetType,
      RowID: this.assetName,
      ScheduleID: scheduleModule.activePersonnelSchedule.ID,
      StartTime: startDate,
      TemplateID: shiftTemplateID,
      Username: this.userName === '' ? null : this.userName,
      WorkspaceID: scheduleModule.activePersonnelWorkspace.ID,
      type: 'new',
      changed: true,
    };

    scheduleModule.addFilterScheduledComponent({ data: localComponent });
  }
    // @ts-ignore
    this.closeAddShiftPopup();
     this.isSavingShift = false;
  }

  get enabledWells() {
    return assetsModule.enabledWells;
  }

  async setUserOptions() {
    this.userOptions.push(scheduleModule.accountDetails.map((item) => ({ value: item.email, text: item.name })));

  this.userOptions[0].sort((a, b) => {
      const textA = a.text.toUpperCase();
      const textB = b.text.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
  }

  async getAllAssetOptions() {
    let currentComponent;
    await scheduleModule.getOperatorAssets();

    this.allAssetTypes = {
      Area: JSON.parse(scheduleModule.operatorAssetsOutput.Areas),
      Pad: JSON.parse(scheduleModule.operatorAssetsOutput.Pads),
      Well: JSON.parse(scheduleModule.operatorAssetsOutput.Wells),
      Route: JSON.parse(scheduleModule.operatorAssetsOutput.Routes),
    };

    if (currentComponent) {
      this.assetType = currentComponent.AssetType;
      this.setAssetOptions();
      this.assetName = currentComponent.Asset;
    } else if (scheduleModule.addShiftRowDetails != null) {
    this.assetType = scheduleModule.addShiftRowDetails.level.charAt(0).toUpperCase()
    + scheduleModule.addShiftRowDetails.level.slice(1);
    this.setAssetOptions();
    await sleep(100);
    this.assetName = scheduleModule.addShiftRowDetails.asset_name;
  }
  }

  getDropdownTimeString(time) {
    function pad(n, width, z) {
      const znew = z || '0';
      const nnew = `${n}`;
      return nnew.length >= width ? nnew : new Array(width - nnew.length + 1).join(znew) + nnew;
    }
    const hourString = parseInt(time.split(':')[0]);
    const minuteString = parseInt(time.split(':')[1]);
    if (hourString === 0) {
      return `12:${pad(minuteString, 2, '0')}am`;
    } if (hourString < 12) {
    return `${hourString}:${pad(minuteString, 2, '0')}am`;
    } if (hourString === 12) {
    return `12:${pad(minuteString, 2, '0')}pm`;
    } if (hourString <= 23) {
      return `${hourString - 12}:${pad(minuteString, 2, '0')}pm`;
    }
    return '';
  }

  async getShiftTemplates() {
    this.templateOptions = [{ value: -1, text: 'Custom' }];
    this.shiftTemplates = await scheduleModule.getShiftTemplates();
    if (this.shiftTemplates) {
      for (let i = 0; i < this.shiftTemplates.length; i++) {
        this.templateOptions.push(
          {
            value: i,
            text: `${this.shiftTemplates[i].TemplateName} (${
              this.getDropdownTimeString(this.shiftTemplates[i].StartTime)
            }-${this.getDropdownTimeString(this.shiftTemplates[i].EndTime)})`,
          },
        );
      }
    }
  }

  async setAssetOptions() {
    if (this.allAssetTypes) {
      this.assetName = '';
      this.assetOptions = this.allAssetTypes[`${this.assetType}`];
    }
  }

  async created() {
    this.setTimeOptions();
    this.setDayOptions();
    this.setUserOptions();
    this.getShiftTemplates();
    await this.getAllAssetOptions();
  }
}
